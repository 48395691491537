import Keycloak from 'keycloak-js'
import { setUserLocaleTimezone } from '../../../Profile'
import loadTokenIntoStore from './loadIdentityTokenIntoStore'
import { creditReferrer } from '../../identity/creditReferrer'
import {
    captureException,
    captureMessage,
    store,
    authSetAccessToken,
    authSetRefreshToken,
    authSetThriveToken,
    authSetServerSessionExpired,
    resetAuthState,
    getPlatform,
    authIsLoggedIn,
    saveState,
    isMicrosoftTeamsTest
} from '@thriveglobal/thrive-web-core'
import { ThriveIdentityAuth } from '../../identity/thriveIdentityAuthApi'
import getTokenWithPermissions from '../../identity/getTokenWithPermissions'
import { saveTncAcceptanceTimestamp } from '../../identity/saveTncAcceptanceTimestamp'
import {
    SESSION_ENDED_PATH,
    REDIRECT_URI
} from '../../protectedRoute/LoginRedirect'
import getClientForPlatform, {
    KeycloakClient
} from './client/getClientForPlatform'
import jwtDecode from 'jwt-decode'
import { CallsPlatform } from './callsAdapter'

const FORCE_TOKEN_REFRESH_TIME = -1
const REFRESH_ERROR_NAME = 'ExpiredSessionError'
type RefreshStatusType = 'REFRESH_SUCCESS' | 'SESSION_ENDED'

function clearAuthStateAndStorage() {
    store.dispatch(resetAuthState())
    localStorage.clear()
}

export function loadTokensIntoKeycloak(
    keycloak: Keycloak,
    accessToken: string,
    refreshToken: string
) {
    try {
        keycloak.init({
            enableLogging: true,
            checkLoginIframe: false,
            refreshToken,
            token: accessToken,
            idToken: keycloak.idToken
        })
    } catch (error) {
        captureException(error, {
            message: 'loadTokensIntoKeycloak failed to call keycloak init'
        })
    }
    return keycloak
}

export function isTokenExpired(exp: number) {
    const now = Date.now()
    const expiration = exp * 1000
    return now > expiration
}

export function getClientId(): KeycloakClient {
    const authStore = store.getState().auth
    return authStore.clientId as KeycloakClient
}

// Temporary for now, Cathal will investigate further
export function getClientFromRefreshToken(refreshToken: string) {
    try {
        const tokenPayload = jwtDecode(refreshToken) as any
        return tokenPayload.azp
    } catch (error) {
        captureException(error, {
            message:
                'Failed to get client id from refresh token, falling back to stored client id'
        })
        return getClientId()
    }
}

export async function refreshKeycloakAccessToken(): Promise<RefreshStatusType | null> {
    const authStore = store.getState().auth
    const refreshToken = authStore.refreshToken
    const refreshTokenExp = authStore.refreshTokenExpiration
    if (isTokenExpired(refreshTokenExp)) {
        // Do nothing and return null. The caller should check the token expiration and logout
        return null
    }
    const clientId = getClientFromRefreshToken(refreshToken)
    try {
        captureMessage('sign-in | Attempting to refresh the token')
        const { access_token, id_token, refresh_token } =
            await ThriveIdentityAuth.refresh(refreshToken, clientId)
        loadTokenIntoStore({
            accessToken: access_token,
            idToken: id_token,
            refreshToken: refresh_token
        })
        captureMessage('sign-in | Identity token refresh success')
        return 'REFRESH_SUCCESS'
    } catch (error) {
        captureException(error as Error, {
            message: 'Keycloak token refresh failed'
        })
        if (error.name === REFRESH_ERROR_NAME) {
            return 'SESSION_ENDED'
        }
        throw error
    }
}

export async function isKeycloakSessionStillActive(): Promise<boolean> {
    const refreshStatus = await refreshKeycloakAccessToken()
    if (refreshStatus === 'SESSION_ENDED' || refreshStatus === null) {
        return false
    }
    return true
}

export async function refreshKeycloakToken() {
    const refreshStatus = await refreshKeycloakAccessToken()

    if (refreshStatus === 'SESSION_ENDED') {
        saveState(REDIRECT_URI, window.location.href)
        // If in microsoft teams and we are doing a login, redirect to ms-teams/auth
        if (isMicrosoftTeamsTest()) {
            captureMessage(`User session ended, sending ${SESSION_ENDED_PATH}`)
            store.dispatch(authSetServerSessionExpired(false))
            store.dispatch(authIsLoggedIn(false))
            // Remove the first / from the pathname as it is added to the relay
            window.location.href = `/ms-teams/auth?relay=${window.location.pathname.substring(
                1
            )}`
            return
        }
        captureMessage(`User session ended, sending ${SESSION_ENDED_PATH}`)
        store.dispatch(authSetServerSessionExpired(true))
        window.location.href = SESSION_ENDED_PATH
    }
}

export async function refreshKeycloakTokenInternal(keycloak: Keycloak) {
    try {
        const refreshed = await keycloak.updateToken(FORCE_TOKEN_REFRESH_TIME)
        if (refreshed) {
            store.dispatch(authSetThriveToken(keycloak.token))
            store.dispatch(authSetAccessToken(keycloak.token))
            store.dispatch(authSetRefreshToken(keycloak.refreshToken))
            loadTokenIntoStore({
                accessToken: keycloak.token,
                idToken: keycloak.idToken,
                refreshToken: keycloak.refreshToken
            })
        }
    } catch (error) {
        captureException(error as Error, {
            message: 'Keycloak token refresh failed'
        })
        throw error
    }
}

interface ISignOutKeycloak {
    postLogoutUrl?: string
}
export async function signOutKeycloak({
    postLogoutUrl
}: ISignOutKeycloak = {}) {
    const authStore = store.getState().auth
    const idToken = authStore.tokenId
    const accessToken = authStore.accessToken
    const clientId = getClientId()
    try {
        await ThriveIdentityAuth.revoke(accessToken, clientId)
    } catch (error) {
        captureException(error as Error, {
            message: 'ThriveIdentityAuth sign out failed'
        })
        clearAuthStateAndStorage()
        captureMessage('sign-in | Fallback Identity sign out complete')
        if (postLogoutUrl) {
            window.location.href = postLogoutUrl
            return
        }
        window.location.href = `${window.location.origin}/logout`
        return
    }
    clearAuthStateAndStorage()
    captureMessage('sign-in | Identity sign out complete')
    const logoutUrl = ThriveIdentityAuth.getLogoutUrl(
        idToken,
        clientId,
        postLogoutUrl
    )
    window.location.href = logoutUrl
}

interface UpdateStoreAndMaybeKeycloakInterface {
    keycloak?: Keycloak
    accessToken: string
    refreshToken: string
    idToken?: string
    realm: 'master' | 'ThriveGlobal'
    acceptedTnC?: boolean
    referrerId?: string
}

export async function updateStoreAndMaybeKeycloak({
    keycloak,
    accessToken,
    refreshToken,
    idToken,
    realm,
    acceptedTnC,
    referrerId
}: UpdateStoreAndMaybeKeycloakInterface) {
    // Temporary try for now. Fallback to tokens without permissions
    const tokens = (await exchangeForTokensWithPermissions(
        accessToken,
        realm
    )) || {
        accessToken: accessToken,
        refreshToken: refreshToken
    }
    if (keycloak) {
        // Update internal state of keycloak with extended tokens
        loadTokensIntoKeycloak(
            keycloak,
            tokens.accessToken,
            tokens.refreshToken
        )
    }
    if (referrerId) {
        try {
            await creditReferrer(referrerId, tokens.accessToken)
        } catch (error) {
            captureException(error, {
                message: 'Failed to call creditReferrer'
            })
        }
    }
    loadTokenIntoStore({
        accessToken: tokens.accessToken,
        idToken: idToken,
        refreshToken: tokens.refreshToken
    })
    await trySaveLocaleAndTncAcceptance(acceptedTnC)
}

export async function trySaveLocaleAndTncAcceptance(acceptedTnC: boolean) {
    try {
        await setUserLocaleTimezone()
    } catch (error) {
        captureException(
            error as Error,
            { message: 'Failed to set user locale timezone' },
            'sign-in'
        )
    }
    if (acceptedTnC) {
        try {
            await saveTncAcceptanceTimestamp()
        } catch (error) {
            captureException(
                error as Error,
                { message: 'Failed to save TnC Acceptance Timestamp' },
                'sign-in'
            )
        }
    }
}

interface TokenObjectType {
    accessToken: string
    refreshToken: string
}

export async function exchangeForTokensWithPermissions(
    accessToken: string,
    realm: 'master' | 'ThriveGlobal'
): Promise<TokenObjectType | null> {
    try {
        const tokens = await getTokenWithPermissions(accessToken, realm)
        return {
            accessToken: tokens.access_token,
            refreshToken: tokens.refresh_token
        }
    } catch (error) {
        captureException(error as Error, {
            message: 'Failed to get token with permissions'
        })
        return null
    }
}

interface IGetCurrentClientOrBestMatchedClient {
    callsPlatform?: CallsPlatform
}
export function getCurrentClientOrBestMatchedClient({
    callsPlatform
}: IGetCurrentClientOrBestMatchedClient = {}): KeycloakClient {
    const client = getClientId()
    if (client) {
        return client
    }
    const platform = getPlatform()
    return getClientForPlatform(platform, callsPlatform)
}
